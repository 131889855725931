"use strict";

// Class definition
var selects = function () {

  var selects = function() {
    var form = $('form.new_administration_select, form.edit_administration_select');

    form.on('click', '.remove_record', function(event) {
      var target = $(this).closest('.' + $(this).data('target'));
      target.find('.destroy-check').val('1');
      target.hide();
      return event.preventDefault();
    });

    form.on('click', '.add_tr', function(event) {
      var regexp, time;
      time = new Date().getTime();
      regexp = new RegExp($(this).data('id'), 'g');
      $('#select_items').append($(this).data('fields').replace(regexp, time));
      return event.preventDefault();
    });
  }

  return {
    // public functions
    init: function() {
      selects();
    }
  };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = selects;
}
