"use strict";

// Class definition
var Calendars = function () {

  /**
    * Get time template for time and all-day
    * @param {Schedule} schedule - schedule
    * @param {boolean} isAllDay - isAllDay or hasMultiDates
    * @returns {string}
  */
  function getTimeTemplate(schedule) {
    var html = [];
    var start = moment(schedule.start.toUTCString());
    html.push('<div style="background-color:' + schedule.bgColor + '; color: white;">')
    html.push('<strong>' + start.format('HH:mm') + '</strong> ');
    html.push('<span class="fal fa-' + schedule.raw.calendarIcon + '"></span>');
    html.push(' ' + schedule.title);
    html.push('</div>');
    return html.join('');
  }

  function clearCalendar(calendar){
    calendar.clear();
  }

  function onClickNavi(e, calendar) {
    // console.log(e)
    // console.log(e.target)
    var action = e.target.getAttribute('data-action');
    // console.log("Click Navi action:" + action)
    switch (action) {
      case 'move-prev':
        calendar.prev();
        break;
      case 'move-next':
        calendar.next();
        break;
      case 'move-today':
        calendar.today();
        break;
      default:
        return;
    }

    setRenderRangeText(calendar);
    callAjax(calendar, $('#calendars').val(), $('#archers').val())
  }

  function setRenderRangeText(calendar) {
    var renderRange = document.getElementById('renderRange');
    var options = calendar.getOptions();
    var viewName = calendar.getViewName();
    var html = [];
    if (viewName === 'day') {
      html.push(moment(calendar.getDate().getTime()).format('YYYY.MM.DD'));
    } else if (viewName === 'month' &&
      (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)) {
      html.push(moment(calendar.getDate().getTime()).format('YYYY.MM'));
    } else {
      html.push(moment(calendar.getDateRangeStart().getTime()).format('YYYY.MM.DD'));
      html.push(' ~ ');
      html.push(moment(calendar.getDateRangeEnd().getTime()).format(' MM.DD'));
    }
    renderRange.innerHTML = html.join('');
  }

  function callAjax(calendar, menu_option_ids, archer_ids){
    clearCalendar(calendar);
    Rails.ajax({
      url: 'calendars',
      data: jQuery.param({
        menu_option_ids: menu_option_ids,
        archer_ids: archer_ids,
        start: moment(moment(calendar.getDateRangeStart().getTime()).format('YYYY-MM-DDTHH:mm:ss') + '+00:00').valueOf(),
        end: moment(moment(calendar.getDateRangeEnd().getTime()).format('YYYY-MM-DDTHH:mm:ss') + '+00:00').valueOf()
      }),
      type: "GET",
      dataType: 'json',
      success: function(data) {
        console.log(data)
        calendar.createSchedules(data.schedules);
        calendar.setCalendars(data.calendars);
      }
    })
  }

  var calendars = function() {

    var calendar = new tui.Calendar('#calendar', {
      defaultView: 'month',
      taskView: false,
      disableDblClick: true,
      disableClick: true,
      useCreationPopup:false,
      useDetailPopup: true,
      // week: {
      //   daynames: <%=t('date.day_names').map(&:capitalize).to_s.html_safe%>
      // },
      // month: {
      //   daynames: <%=t('date.day_names').map(&:capitalize).to_s.html_safe%>
      // },
      template: {
        monthGridHeaderExceed: function(hiddenSchedules) {
          return '<span class="tui-full-calendar-weekday-grid-more-schedules">+' + hiddenSchedules + '</span>';
        },
        time: function(schedule) {
          return getTimeTemplate(schedule);
        },
        allday: function(schedule) {
          return getTimeTemplate(schedule);
        },

      },
    });

    calendar.on({
      'clickSchedule': function(e) {
        $('.tui-full-calendar-calendar-dot').attr('style', 'background-color:'  + e.calendar.bgColor + ';')
        $('.tui-full-calendar-popup-top-line').attr('style', 'background-color:'  + e.schedule.raw.scheduleColor + ';')
      }
    });


    $('#calendars').multiselect({
      buttonWidth: '100%',
      maxHeight: 400,
      includeSelectAllOption: true,
      selectAllText: 'Todos!',
      nonSelectedText: 'Seleccione!',
      nSelectedText: ' - Demasiadas selecciones!',
      allSelectedText: 'Todos...',
      onChange: function(option, checked, select) {
        // console.log('Calendar Changed option ' + $(option).val() + '.');
        callAjax(calendar, $('#calendars').val(), $('#archers').val())
      },
      onSelectAll: function() {
        // console.log('Calendar onSelectAll triggered!');
        callAjax(calendar, 'all', $('#archers').val())
      },
      onDeselectAll: function() {
        // console.log('Calendar onDeselectAll triggered!');
        clearCalendar(calendar);
      },
      onReset: function() {
        // console.log('Calendar onReset clicked!');
        clearCalendar(calendar);
      }
    });

    $('#archers').multiselect({
      buttonWidth: '100%',
      maxHeight: 400,
      includeSelectAllOption: true,
      selectAllText: 'Todos!',
      nonSelectedText: 'Seleccione!',
      nSelectedText: ' - Demasiadas selecciones!',
      allSelectedText: 'Todos...',
      onChange: function(option, checked, select) {
        // console.log('Archer Changed option ' + $(option).val() + '.');
        callAjax(calendar, $('#calendars').val(), $('#archers').val())
      },
      onSelectAll: function() {
        // console.log('Archer onSelectAll triggered!');
        callAjax(calendar, $('#calendars').val(), 'all')
      },
      onDeselectAll: function() {
        // console.log('Archer onDeselectAll triggered!');
        clearCalendar(calendar);
      },
      onReset: function() {
        // console.log('Archer onReset clicked!');
        clearCalendar(calendar);
      }
    });

    $('#calendar-type').multiselect({
      buttonWidth: '100%',
      onChange: function(option, checked, select) {
        // console.log('Calendar type Changed option ' + $(option).val() + '.');
        var options = calendar.getOptions();
        var viewName = '';
        switch ($(option).val()) {
          case 'daily':
            viewName = 'day';
            break;
          case 'weekly':
            viewName = 'week';
            break;
          case 'monthly':
            options.month.visibleWeeksCount = 0;
            viewName = 'month';
            break;
          default:
            break;
        }
        calendar.setOptions(options, true);
        calendar.changeView(viewName, true);
        setRenderRangeText(calendar);
        callAjax(calendar, $('#calendars').val(), $('#archers').val())
      }
    });

    $('#menu-navi').click(function(e){
      onClickNavi(e, calendar);
    });
  }

  return {
    // public functions
    init: function() {
      calendars();
    }
  };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = Calendars;
}
