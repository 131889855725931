// Import just what we need

import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';


require('./bootstrap-multiselect');
require("zingchart");
require("@claviska/jquery-minicolors");
window.moment = require("moment");

require('./jquery-multi-select/jquery.quicksearch');
require('./jquery-multi-select/jquery.multi-select');

import Calendars from './calendars';
import menuOptions from './menu_options';
import selects from './selects';

window.Calendars = Calendars;
window.menuOptions = menuOptions;
window.selects = selects;

// window.addEventListener('DOMContentLoaded', event => {
window.addEventListener('turbolinks:load', event => {

  // Toggle the side navigation
  const sidebarToggle = document.body.querySelector('#sidebarToggle');
  if (sidebarToggle) {
      // Uncomment Below to persist sidebar toggle between refreshes
      // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      //     document.body.classList.toggle('sb-sidenav-toggled');
      // }
      sidebarToggle.addEventListener('click', event => {
          event.preventDefault();
          document.body.classList.toggle('sb-sidenav-toggled');
          localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
      });
  }

  // Minicolors
  $('[data-minicolors]').each(function(i, elem) {
    var input = $(this);
    input.minicolors(input.data('minicolors'));
  });
});

