"use strict";

// Class definition
var menuOptions = function () {

  var menuOptions = function() {
    var form = $('form.new_administration_menu_option, form.edit_administration_menu_option');

    form.on('click', '.remove_record', function(event) {
      var target = $(this).closest('.' + $(this).data('target'));
      target.find('.destroy-check').val('1');
      target.hide();
      return event.preventDefault();
    });
  
    form.on('click', '.add_fields', function(event) {
      var regexp, time;
      time = new Date().getTime();
      regexp = new RegExp($(this).data('id'), 'g');
      $('#fields').append($(this).data('fields').replace(regexp, time));
      return event.preventDefault();
    });
  
    form.on('click', '.add_field_in_fieldset', function(event) {
      var regexp, time;
      time = new Date().getTime();
      regexp = new RegExp($(this).data('id'), 'g');
      $(this).parent().find('.inner-fields').append($(this).data('fields').replace(regexp, time));
      return event.preventDefault();
    });

    form.on('change', '.input-input-type', function(event) {
      var target = $(this).closest('.field_row');
      if ($(this).val() == 'select') {
        target.find('.administration_menu_option_form_fields_select_id').show();
      } else {
        target.find('.administration_menu_option_form_fields_select_id').hide();
      }
    })
  }

  return {
    // public functions
    init: function() {
      menuOptions();
    }
  };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = menuOptions;
}
